import { BulletList } from '../../components/atoms/BulletList';
import { LinkButton } from '../../components/atoms/Button';
import { Copy, H1, H2, H3 } from '../../components/atoms/Typography';

const TermsOfUse = () => {
  return (
    <>
      <H1>Terms Of Use</H1>
      <Copy marginBottom={32}>
        <strong>Effective Date:</strong> {new Date().toISOString().slice(0, 10)}
      </Copy>
      <Copy styleLevel={2} marginBottom={16}>
        Welcome to JobsBoard.io! By accessing and using our services, you are agreeing to be bound by these Terms of
        Service (“Terms”). When we refer to JobsBoard.io, whether as a corporation or entity, it is collectively known
        as "JobsBoard.io," representing the entire company, including all subsidiaries. We appreciate your visit and the
        opportunity to provide you with information about JobsBoard.io. Thank you for taking the time to explore our
        platform.
      </Copy>
      <Copy styleLevel={2} marginBottom={16}>
        We are committed to ensuring that you, and all our visitors, have a unique and positive experience while using
        JobsBoard.io. It is our priority that when you engage with JobsBoard.io, you are fully informed about your legal
        rights and obligations. To achieve this, we have carefully crafted these Terms as the legally binding terms that
        govern your use of JobsBoard.io. We encourage you to read these Terms thoroughly before using JobsBoard.io, as
        they significantly impact your legal rights and obligations.
      </Copy>
      <Copy styleLevel={2} marginBottom={16}>
        We understand the importance of your privacy. In our commitment to safeguarding it, we offer a comprehensive
        notice that explains our online information practices. This notice empowers you with the choices you can make
        regarding how your information is collected and utilized on this site. Your privacy matters, and we are
        dedicated to providing transparency and control over your personal data. Click{' '}
        <LinkButton onClick={() => {}}>here</LinkButton> to take you to our Privacy Policy, which form a part of these
        Terms.
      </Copy>
      <H2>Intellectual Property</H2>
      <Copy styleLevel={2} marginBottom={16}>
        All copyright, database rights, and other intellectual property rights in the material on this Site (the
        'Content') belong to JobsBoard.io or its licensors. Individuals accessing and/or using this Site will not
        acquire intellectual or other proprietary rights in the Content.
      </Copy>
      <Copy styleLevel={2} marginBottom={16}>
        You may print or download content from this Site to a local hard disk or other electronic storage medium for
        your personal use only.
      </Copy>

      <Copy styleLevel={2} marginBottom={16}>
        Any other reproduction, distribution, sale, or publication of the Site's Content, as well as the subscription
        products and services provided by JobsBoard.io, is strictly prohibited without prior written permission."
      </Copy>
      <H2>Legal capacity</H2>
      <Copy styleLevel={2} marginBottom={16}>
        JobsBoard.io is designed as a general audience website for adults and is not intended for individuals under the
        age of 18. By using JobsBoard.io, you affirm that you possess the legal capacity to enter into the agreement
        outlined in these Terms. This includes confirming that you are of sufficient age, mental capacity, and are
        otherwise entitled to be legally bound in a contract.
      </Copy>
      <H3>Applicable Law</H3>
      <Copy styleLevel={2} marginBottom={16}>
        These terms shall be governed by and construed in accordance with the laws of England and Wales. Any disputes
        arising in connection with these terms shall be exclusively subject to the jurisdiction of the courts of England
        and Wales.
      </Copy>
      <H3>Jurisdiction</H3>
      <Copy styleLevel={2} marginBottom={16}>
        These General Terms and Conditions, as well as any dispute or claim arising out of or in connection with them,
        their subject matter, or formation (including non-contractual disputes or claims), shall be governed by and
        interpreted in accordance with the laws of England and Wales.
      </Copy>

      <H2>Account</H2>
      <Copy styleLevel={2} marginBottom={16}>
        To access JobsBoard.io, each user must create and use their individual membership account. When establishing an
        account or submitting career information through JobsBoard.io (including forms on the site or resume uploads),
        you acknowledge and agree to assume sole responsibility for the accuracy of your User Content (as defined
        below). Recognize that JobsBoard.io may collect and utilize data as specified in these Terms and the Privacy
        Policy.
      </Copy>
      <Copy styleLevel={2} marginBottom={16}>
        You understand and consent to JobsBoard.io tailoring information to align with your interests. Please be aware
        that you have no ownership rights in your account or any email account registered with us. If you request
        information deletion, it will no longer be publicly available, and we will take reasonable steps, as permissible
        by law, to delete it.
      </Copy>
      <Copy styleLevel={2} marginBottom={16}>
        JobsBoard.io is not obligated to maintain any account and may delete it at its sole and absolute discretion if
        you violate these Terms. JobsBoard.io reserves the right to review emails in any account you establish with us,
        and you acknowledge that you have no reasonable expectation of privacy in this regard.
      </Copy>
      <Copy styleLevel={2} marginBottom={16}>
        JobsBoard.io does not authorize shared accounts. Group rates or domain accounts are under the control of the
        member who originally created the account and whose payment method has been charged (referred to as the "Account
        Owner"). This arrangement is based on discussions and contractual agreements with JobsBoard.io.
      </Copy>
      <Copy styleLevel={2} marginBottom={16}>
        The Account Owner is prohibited from inviting or authorizing third parties to share in their subscription unless
        explicitly outlined in a contractual agreement with JobsBoard.io. Individuals attempting to join a shared
        account will be required to create their own separate and independent profile. This ensures a distinct and
        personalized experience with our products and services.
      </Copy>
      <H2>Payments and subscription</H2>
      <Copy styleLevel={2} marginBottom={16}>
        When you purchase any of our fee-based services, whether on a one-time or subscription basis, you agree that
        JobsBoard.io's third-party vendors may store your payment information securely. By making a purchase, you also
        commit to paying the applicable fees for the ordered products/services, including periodic fees for monthly or
        annual subscriptions, as they become due, along with any related taxes. You further agree to reimburse us for
        all collection costs and interest on overdue amounts.
      </Copy>
      <Copy styleLevel={2} marginBottom={16}>
        Recurring charges will be fixed at the price agreed upon at the time of purchase. Specific billing terms for the
        products you order will be outlined on the offer page on JobsBoard.io. If you opt for automatic or recurring
        billing, you will be informed of the billing terms on the JobsBoard.io site, including instructions on how to
        stop recurring billing. By signing up for JobsBoard.io products or services with recurring billing, you are
        deemed to agree to those specific billing terms as described on the JobsBoard.io site. Failure to pay may result
        in the termination of your subscription.
      </Copy>
      <Copy styleLevel={2} marginBottom={16}>
        Depending on your location, payment method, and where your payment method was issued, your transaction may be
        subject to foreign exchange fees or variations in prices due to exchange rates. Foreign currency exchange fees
        imposed by your bank or card issuer are not the responsibility of JobsBoard.io, and we will not compensate or
        reimburse for such charges.
      </Copy>
      <Copy styleLevel={2} marginBottom={16}>
        JobsBoard.io reserves the right to adjust prices for products/services at any time. If your payment method, such
        as a credit card, reaches its expiration date and you do not update your payment information or cancel your
        account, you authorize us to continue billing that payment method, and you remain responsible for any
        uncollected amounts.
      </Copy>
      <Copy styleLevel={2} marginBottom={16}>
        Your obligation to pay fees continues through the end of the subscription period during which you cancel your
        subscription. All applicable taxes are calculated based on the billing information you provide at the time of
        purchase. Service cancellation or suspension can be done through your Account Page. Please note that
        JobsBoard.io does not guarantee refunds. Any products or services purchased are subject to these Terms and any
        additional terms related to their provision.
      </Copy>
      <H2>Member conduct</H2>
      <H3> Responsible use of JobsBoard.io</H3>
      <Copy styleLevel={2} marginBottom={16}>
        As a condition of your use of the JobsBoard.io, you warrant to us that you will not use the website for any
        purpose that is unlawful or prohibited by these Terms and Conditions and notices. You must refrain from using
        the website in any manner that could damage, disable, overburden, or impair the website or interfere with any
        other party's use and enjoyment of the website. Additionally, you must not attempt to obtain any materials or
        information through any means not intentionally made available or provided for through the Website.
      </Copy>
      <H3>Content limitations</H3>
      <Copy styleLevel={2} marginBottom={16}>
        At JobsBoard.io, we have set forth specific guidelines to ensure a positive and respectful community experience.
        Please refrain from posting resumes, cover letters, sending emails, submitting to, or publishing through forums
        available on JobsBoard.io, or engaging in any behavior that, in our view:
      </Copy>
      <Copy styleLevel={2} marginBottom={16}>
        <BulletList>
          <li>
            <p>
              <strong>Harasses or Offends:</strong>
              Libels, defames, invades privacy, stalks, is obscene, pornographic, racist, abusive, harassing,
              threatening, or offensive.
            </p>
          </li>
          <li>
            <p>
              <strong>Negatively Portrays Actors:</strong>
              Disparages, criticizes, belittles, parodies, or portrays negatively any actor appearing in the content.
            </p>
          </li>
          <li>
            <p>
              <strong>Harms Children:</strong>
              Seeks to exploit or harm children by exposing them to inappropriate content, asking for personally
              identifiable details, or otherwise.
            </p>
          </li>
          <li>
            <p>
              <strong>Violates Intellectual Property Rights:</strong>
              Infringes any intellectual property or other right of any entity or person, including violating
              copyrights, trademarks, or rights of publicity.
            </p>
          </li>
          <li>
            <p>
              <strong>Breaks the Law:</strong>
              Violates any law or may be considered to violate any law.
            </p>
          </li>
          <li>
            <p>
              <strong>Unauthorized Transmission:</strong>
              Transmits content for which you do not have the right under any contractual or other relationship (e.g.,
              inside information, proprietary or confidential information received in the context of employment or a
              non-disclosure agreement).
            </p>
          </li>
          <li>
            <p>
              <strong>Promotes Illegal Activity:</strong>
              Advocates or promotes illegal activity.
            </p>
          </li>
          <li>
            <p>
              <strong>Misrepresentation:</strong>
              Impersonates or misrepresents your connection to any other entity or person or manipulates headers or
              identifiers to disguise the origin of the content.
            </p>
          </li>
          <li>
            <p>
              <strong>Solicitation:</strong>
              Solicits funds, advertisers, or sponsors.
            </p>
          </li>
          <li>
            <p>
              <strong>Malicious Programs:</strong>
              Includes programs containing viruses, worms, ‘Trojan horses,’ or any other code designed to disrupt or
              damage computer software, hardware, or telecommunications.
            </p>
          </li>
          <li>
            <p>
              <strong>Disruption of Services:</strong>
              Disrupts the normal flow of dialogue, causes screens to scroll faster than users can type, or otherwise
              interferes with real-time activities on JobsBoard.io.
            </p>
          </li>
          <li>
            <p>
              <strong>Unauthorized Copying:</strong>
              Copies any pages or images on JobsBoard.io without appropriate authority.
            </p>
          </li>
          <li>
            <p>
              <strong>File Format Restrictions:</strong>
              Includes MP3 format files.
            </p>
          </li>
          <li>
            <p>
              <strong>Pyramid or Similar Schemes:</strong>
              Amounts to a ‘pyramid’ or similar scheme.
            </p>
          </li>
          <li>
            <p>
              <strong>Data Warehousing:</strong>
              Amounts to ‘data warehousing,’ i.e., using web space for storage of large files or data linked from other
              sites without providing accompanying content to direct traffic via us.
            </p>
          </li>
          <li>
            <p>
              <strong>Policy Disobedience:</strong>
              Disobeys any policies or regulations established from time to time regarding the use of JobsBoard.io or
              any connected networks.
            </p>
          </li>
          <li>
            <p>
              <strong>Inappropriate Links:</strong>
              Contains links to other sites that fall within the descriptions set out in (a) to (p) above.
            </p>
          </li>
        </BulletList>
      </Copy>
      <H2>Limitations on liability</H2>
      <H3>Service Delivery</H3>

      <Copy styleLevel={2} marginBottom={16}>
        We strive to maintain the accessibility of JobsBoard.io 24 hours a day. However, we shall not be liable for any
        costs, expenses, losses, or liabilities that you, your business, or any other person may incur if the site
        becomes unavailable at any time or if your access to the website is interrupted, restricted, or delayed for any
        reason.
      </Copy>

      <Copy styleLevel={2} marginBottom={16}>
        While we strive to provide accurate descriptions for every product or service offered on JobsBoard.io, it's
        important to acknowledge our human element. As fallible beings, we cannot guarantee that product specifications,
        pricing, or other content on the online services are always complete, accurate, reliable, current, or
        error-free.
      </Copy>
      <H3>Product specifications; pricing; typographical errors</H3>
      <Copy styleLevel={2} marginBottom={16}>
        While we strive to provide accurate descriptions for every product or service offered on JobsBoard.io, it's
        important to acknowledge our human element. As fallible beings, we cannot guarantee that product specifications,
        pricing, or other content on the online services are always complete, accurate, reliable, current, or
        error-free.
      </Copy>
      <Copy styleLevel={2} marginBottom={16}>
        In the event of any errors concerning pricing or specifications, JobsBoard.io reserves the right to refuse or
        cancel any orders at its sole discretion. If your credit card or other account was charged before cancellation,
        we will issue a credit to your account equivalent to the charge. Please note that additional terms may apply. We
        appreciate your understanding and cooperation as we work to enhance your experience on JobsBoard.io.
      </Copy>
      <H3> Local regulations</H3>
      <Copy styleLevel={2} marginBottom={16}>
        JobsBoard.io makes no representation that Materials or other content on JobsBoard.io site are appropriate or
        available for use outside of the England and Wales. If you choose to access JobsBoard.io site from other locations
        you do so on your own initiative and at your own risk. You are responsible for complying with local laws, if and
        to the extent local laws are applicable.
      </Copy>
      <H3>Disclaimer of warranties.</H3>
      <Copy styleLevel={2} marginBottom={16}>
        YOU EXPRESSLY AGREE THAT USE OF THE JOBSBOARD.IO SITE IS AT YOUR SOLE RISK. THE JOBSBOARD.IO SITE AND MATERIALS
        CONTAINED THEREIN ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. JOBSBOARD.IO EXPRESSLY DISCLAIMS ALL
        WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION ANY WARRANTY OF MERCHANTABILITY,
        FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT.
      </Copy>

      <H2>Updates to Terms.</H2>

      <Copy styleLevel={2} marginBottom={16}>
        Kindly be aware that these Terms may undergo revisions and reissues, which will be posted on this page in a
        prospective manner. By using JobsBoard.io, you consent and agree to receive notifications about updates to these
        Terms through our posting of revised terms on the platform. We recommend visiting this page regularly to stay
        informed about the current terms. Your ongoing use of JobsBoard.io will be considered as an irrevocable
        acceptance of any revisions made in accordance with these Terms. Your understanding and continued engagement are
        appreciated as we strive to keep you informed and ensure a transparent user experience on JobsBoard.io.
      </Copy>

      <p>
        <strong>JobsBoard.io</strong>
        <br />
        Email: <a href="mailto:contact@JobsBoard.io">contact@JobsBoard.io</a>
        <br />
      </p>
    </>
  );
};

export default TermsOfUse;
